/* src/components/style/card/CardList.css */

/* Container untuk menampung semua kartu */
.card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Mengatur grid */
    gap: 16px; /* Jarak antar kartu */
    padding: 16px; /* Jarak dalam dari tepi container */
  }
  
  /* Gaya untuk masing-masing kartu */
  .custom-card {
    background-color: white;
    border: 1px solid #e0e0e0; /* Tambahkan border */
    border-radius: 8px; /* Membuat sudut membulat */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sedikit bayangan */
    transition: transform 0.2s ease-in-out;
  }
  
  /* Menambahkan efek hover */
  .custom-card:hover {
    transform: scale(1.05); /* Efek zoom saat di-hover */
  }
  