body {
  padding-top: 64px;
  /* Sesuaikan padding untuk menghindari konten tertutupi */
}

/* Navbar container */
.navbar-container {
  display: flex;
  gap: 1px;

}



.navbar-item {
  text-align: center;
  width: 100px;
  border-radius: 10px;
  padding: 10px;
  margin: 0px 0px;
  color: white;
  text-decoration: none;
}
.navbar-item:hover{
  background-color: rgb(0, 74, 139);
}

/* Elemen tersembunyi untuk responsif */
.navbar-hidden {
  display: none;
}

.navbar-appbar {
  border-radius: 5px;
  margin-top: 10px;
  z-index: 1100;
}

/* Tampilkan menu di ukuran layar besar */
@media (min-width: 900px) {
  .navbar-hidden {
    display: flex;
  }
}