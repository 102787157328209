* {
    font-family: Arial, Helvetica, sans-serif;
}

.display{
    display: none;
}

.container{
    margin-top: 60px;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

div {
    padding: 1%;
}

@media (max-width: 768px) {
    .navbar ul {
        flex-direction: column;
    }
}

@media (min-width: 960px) {
    h3 {
      font-size: 24px;
    }
}

.kosong{
    color: red;
}