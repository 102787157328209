/* exitbutton.css */

.exit-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Mengatur elemen ke kiri */
    margin-bottom: 20px;
}

.exit-button-container h2 {
    font-size: 24px;
}

.exit-button-container{
    margin-top: -10px;
    padding: 0px 0px;
}

.exit-button {
    font-size: 12px;
    padding: 9px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.exit-button-container .exit-button:hover {
    background-color: #0056b3;
    /* Warna hover yang diinginkan */
}