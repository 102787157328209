/* src/components/style/Card.css */
.card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.3s ease;
  }
  
  .card-container{
    padding: 0px 0px;
  }
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .card-link {

    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .card-link:hover {
    color: #007bff;
  }
  